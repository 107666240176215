import React from "react"
import { graphql, Link } from "gatsby"
import Loadable from "react-loadable"

import Layout from "../components/layout"
import CTABlock from "../components/blocks/cta_block"

const loader = () => <div>Loading...</div>

const EventsCarousel = Loadable({
  loader: () => import("../components/events-slider"),
  loading: loader,
})

const AthletesPage = ({ data }) => {
  return (
    <>
      <Layout
        isContent={true}
        subMenu={data.wagtail.page.children}
        button={data.wagtail.page.button}
        parent={"athletes"}
        siteTitle={"Athletes"}
      >
        <section className="breadcrumb-area breadcrumb-sub-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb nav-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="about-area main-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1>{data.wagtail.page.title}</h1>
                <p>{data.wagtail.page.descriptionSimple}</p>
                <div className="clib-area">
                  <div className="club-img full-width-image">
                    <img src={data.wagtail.page.feedImage.src} alt="swim" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="type-athletes-area main-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h4>TYPES OF COMPETITIVE SWIMMERS</h4>
              </div>
            </div>
            <div className="space"></div>
            <div className="row types">
              {data.wagtail.page.typeCompetitives.map(item => (
                <div
                  className="d-inline col-lg-4 overflow-auto"
                  key={`${item.name}`}
                >
                  <div className="box">
                    <h2>{item.name}</h2>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <CTABlock data={data.wagtail.page.fullContent[0]}></CTABlock>
        <EventsCarousel showEventMonth={false} />
        <CTABlock data={data.wagtail.page.fullContent[1]}></CTABlock>
      </Layout>
    </>
  )
}
export const query = graphql`
  {
    wagtail {
      page(slug: "athletes") {
        ... on AthletesPage {
          descriptionSimple
          id
          children {
            ... on AthletePage {
              id
              title
              slug
              live
              isProtected
            }
            ... on EventPage {
              id
              title
              slug
              live
              isProtected
            }
            ... on SimplePage {
              id
              title
              slug
              live
              isProtected
            }
          }
          button {
            name
            text
            url
          }

          body
          feedImage {
            src
            height
            width
          }
          title
          events {
            id
            ... on EventPage {
              clinicname
              clinicDate
              clubname
              location
              eventname
              eventdescription
              initialdate
              locationname
              locationaddress
              athletetype
              slug
              show
              description
              live
              ancestors(order: "-depth", limit: 1) {
                title
                depth
              }
            }
          }
          typeCompetitives {
            ... on CompetitiveBlock {
              name
              description
            }
          }
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
        }
      }
    }
  }
`
export default AthletesPage
